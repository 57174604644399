import React, { useState, useEffect } from "react";
import { LawModel } from "@/src/models/lawModel";
import Image from "next/image";
import DownloadIcon from "@/public/assets/icons/svgs/u_file-download-white.svg";
import Link from "next/link";
import { DateOnlyHelper } from "@/src/helpers/dateHelper";
import LoadingDialog from "../atoms/loadingDialog";
import FileRepository, { PostData } from "@/src/repositories/fileRepository";
import { getOrCreateIdentifier } from "../../helpers/identifierHelper";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import RatingDialog from "../organisms/ratingDialog";
import { toast } from "react-toastify";

type LawProps = {
  law: LawModel;
};

const CardLaw: React.FC<LawProps> = ({ law }) => {
  const [loading, setLoading] = useState(false);
  const [dialogRating, setDialogRating] = useState(false);
  const [identifier, setIdentifier] = useState<string | null>(null);
  let detailUrl = "";
  let date = "";

  const fileRepository = new FileRepository();

  useEffect(() => {
    const id = getOrCreateIdentifier();
    setIdentifier(id);
  }, []);

  const closeDialog = () => {
    setDialogRating(false);
  };

  const handleDownload = async () => {
    setLoading(true);
    const data: PostData = {
      identifier: identifier as string,
      type: law.type_id,
    };

    const postData = await fileRepository.fetchDownload(
      law.id,
      String(law.files?.[law.files.length - 1]?.id),
      data,
    );

    if (postData.code === 200 && !postData.data) {
      setLoading(false);
      setDialogRating(true);
    }

    if (postData.code === 200 && postData.data) {
      setLoading(false);
      if (postData.data.file_path) {
        window.open(postData.data.file_path, "_blank");
      } else {
        toast.error("Gagal mengunduh dokumen!");
      }
    }
  };

  switch (law.type_id) {
    case 1:
      detailUrl = `/dokumen/peraturan/${law.id}/${law.slug}`;
      date = law.approven_date;
      break;
    case 2:
      detailUrl = `/dokumen/monografi/${law.id}/${law.slug}`;
      date = law.updated_at ?? "";
      break;
    case 3:
      detailUrl = `/dokumen/artikel/${law.id}/${law.slug}`;
      date = law.updated_at ?? "";
      break;
    case 4:
      detailUrl = `/dokumen/putusan/${law.id}/${law.slug}`;
      date = law.approven_date ?? "";
      break;
  }

  if (!law) return <></>;

  const hasFiles = (law.files?.length ?? 0) > 0;

  return (
    <>
      {loading && <LoadingDialog />}
      {dialogRating && <RatingDialog onClose={closeDialog} />}
      <div className="flex rounded-md border bg-white font-sans shadow-xl md:w-full">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <h1 className="line-clamp-2 min-h-[2rem] flex-auto text-lg font-semibold text-slate-900">
              {law.category_name} Nomor {law.number}
            </h1>
          </div>
          <div className="mt-4 flex items-baseline">
            <div className="flex min-h-[4rem] space-x-2 text-ellipsis text-sm">
              <p className="line-clamp-3 overflow-hidden text-ellipsis break-words">
                {law.title}
              </p>
            </div>
          </div>
          <div className="flex flex-row pt-4">
            <Image
              priority
              src="/assets/icons/svgs/u_calendar-alt.svg"
              height={28}
              width={28}
              alt="Icon Calendar"
            />
            <span className="pl-2">{DateOnlyHelper(date)}</span>
          </div>
          <div className="flex flex-row pt-2">
            <Image
              priority
              src="/assets/icons/svgs/u_file-download.svg"
              height={28}
              width={28}
              alt="Icon Download"
            />
            {/* <span className="pl-2">{legislation.views || 0}x</span> */}
            <span className="pl-2">
              {law.files?.reduce((sum, file) => sum + file.downloads, 0) || 0}x
            </span>
          </div>
          <div className="mt-3 flex items-center justify-center rounded-lg bg-gray-100 p-2">
            <div className="flex w-full gap-2">
              <Link
                href={String(detailUrl)}
                key={law.id}
                className={`rounded-lg border-2 border-[#191970] bg-white px-4 py-2 text-center text-[#191970] ${
                  hasFiles ? "w-1/2" : "w-full"
                }`}
              >
                Detail
              </Link>
              {hasFiles && (
                <button
                  className="flex w-1/2 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={handleDownload}
                >
                  Unduh
                  <DownloadIcon className="h-6 w-6 text-white" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardLaw;
